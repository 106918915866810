import React, { ReactNode, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./paymentForm";
import "../../styles/paymentModal.css";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_live_51LAupvLftwhjXz1dN1JDGSE44twj7YwppoLSfIRSy0dMYZRN3cdkdDoGeyyhI6ADDCIfdYBz87TNY3kQIxoqqiqm00LW1fDSXO"
);

interface ModalType {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
}

export default function Modal(props: ModalType) {
  const [options, setOptions] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://safekab.co/onlinebooking/create.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              amount: 100,
            }),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData);

          const options = {
            clientSecret: responseData.clientSecret,
            appearance: {
              /*...*/
            },
          };

          setOptions(options);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const handlePaymentResult = (result: any) => {
    // Handle the payment result here
    console.log("Payment result:", result);

    // If payment is successful, navigate to the /orders route
    if (result.success) {
      navigate("/orders");
    }

    // Close the modal or perform other actions based on the result
    props.toggle();
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {props.isOpen && (
        <div className="modal-overlay" onClick={props.toggle}>
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ height: "60%" }}
            className="modal-box"
          >
            <div className="container">
              <div>
                <div style={{ backgroundColor: "#1B5879" }}>
                  <h1
                    style={{
                      fontFamily: "Poppins",
                      paddingTop: "5%",
                      color: "white",
                      paddingBottom: "4%",
                      textAlign: "center",
                    }}
                  >
                    Payment
                  </h1>
                </div>
                <PaymentForm
                  onSubmit={handlePaymentResult}
                  cardElementOptions={undefined}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Elements>
  );
}
