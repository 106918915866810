import React, { useEffect } from "react";
import ResponsiveAppBar from "../mainPageComponents/toolBar";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const OrderSuccess: React.FC = () => {
  const job = sessionStorage.getItem("job");
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate("/orders");
    }, 120000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  const handleHomeButtonClick = () => {
    navigate("/");
  };

  const handleordersButtonClick = () => {
    navigate("/orders");
  };

  return (
    <>
      <div>
        <ResponsiveAppBar />
        <IconButton
          style={{ position: "absolute", top: 79, left: 20 }}
          onClick={handleHomeButtonClick}
        >
          <ArrowBackIcon />
          Home
        </IconButton>
        <IconButton
          style={{ position: "absolute", top: 79, right: 20 }}
          onClick={handleordersButtonClick}
        >
          {" "}
          Jobs
          <ArrowForwardIcon />
        </IconButton>
        <span
          style={{
            position: "absolute",
            top: "22%",
            left: "49%",
            fontSize: "60px",
          }}
          role="img"
          aria-label="Green Arrow"
        >
          ✅
        </span>
        <div
          style={{
            color: "green",
            fontSize: "34px",
            fontFamily: "Poppins",
            textAlign: "center",
            marginTop: "14%",
            fontWeight: "bold",
          }}
        >
          <span>Booking Submitted Successfully </span>
        </div>
        <div
          style={{
            color: "green",
            fontSize: "28px",
            fontFamily: "Poppins",
            textAlign: "center",
            marginTop: "0%",
          }}
        >
          <span> Booking ID: {job} </span>
        </div>
        <div
          style={{
            color: "green",
            fontSize: "20px",
            fontFamily: "Poppins",
            textAlign: "center",
            marginTop: "1%",
          }}
        >
          <span>
            Please Check your Email for further updates of your ride.{" "}
          </span>
        </div>{" "}
        <div
          style={{
            color: "green",
            fontSize: "17px",
            fontFamily: "Poppins",
            textAlign: "center",
            marginTop: "1%",
          }}
        >
          <span>Make Sure to check your Junk folder as well.</span>
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;
