import React, { useState, ChangeEvent, useEffect } from "react";
import "../../styles/modal.scss";
import Button from "@mui/material/Button";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Fab } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import NoteModal from "../modalComponents/noteModal";
import useModal from "../../helpers/useModal";
import { useNavigate } from "react-router-dom";
import * as Base64 from "base-64";
import { DateTime } from "luxon";
import Modal from "../modalComponents/modal";
import { loadStripe } from "@stripe/stripe-js";

interface AnotherFormProps {
  onClose: () => void;
  isLoggedIn: boolean;
}

const AnotherForm: React.FC<AnotherFormProps> = ({ onClose, isLoggedIn }) => {
  const navigate = useNavigate();
  const paymentModal = useModal();
  const car = sessionStorage.getItem("Car");

  const destination = sessionStorage.getItem("Destination");
  const origin = sessionStorage.getItem("Origin");
  const time = sessionStorage.getItem("Time");
  const date = sessionStorage.getItem("Date");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const checkAndSetFormDataInSessionStorage = () => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    if (isLoggedIn != "Y") {
      // Set values in sessionStorage if any of them is missing
      sessionStorage.setItem("Name", formData.name);
      sessionStorage.setItem("Email", formData.email);
      sessionStorage.setItem("Phone", formData.phone);
    }
  };

  checkAndSetFormDataInSessionStorage();

  const isLoggedInZ = sessionStorage.getItem("isLoggedIn");

  const noteModal = useModal();
  const [note, setNote] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleAddNote = () => {
    sessionStorage.removeItem("Note");
    setShowModal(true);
  };

  const handleSaveNote = () => {
    setShowModal(false);
  };

  const handleCancelNote = () => {
    setNote("");
    setShowModal(false);
  };

  console.log("Note", sessionStorage.getItem("Note"));

  const handleClearNote = () => {
    sessionStorage.removeItem("Note");
  };

  const isAccountBusiness = sessionStorage.getItem("accountType");
  const confirmAccount = isAccountBusiness == "true";
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (confirmAccount) {
      navigate("/processingRequest");
    } else {
      navigate("/payment");
    }
    return false;
  };

  function checkForSavedUserId(): string | null {
    try {
      const savedUserId = sessionStorage.getItem("savedUserID");

      if (savedUserId !== null) {
        return savedUserId;
      }

      return null;
    } catch (error) {
      console.error(
        "Error while checking for savedUserId in session storage:",
        error
      );
      return null;
    }
  }

  const userId = checkForSavedUserId();

  if (userId !== null) {
    console.log("savedUserId found in session storage:", userId);
  } else {
    console.log("savedUserId not found in session storage.");
  }

  useEffect(() => {
    console.log("userId changed:", userId);
    // Add additional logic here if needed
  }, [userId]);

  return (
    <>
      <div className="container">
        <div className="ticket basic"></div>

        <div className="ticket airline">
          <div
            className="top"
            style={{ background: process.env.REACT_APP_COLOUR }}
          >
            <Fab
              onClick={() => {
                handleClearNote();
                onClose();
              }}
              style={{
                color: "black",
                background: "white",
                marginLeft: "5px",
                marginTop: "10px",
                height: "16%",
                width: "12%",
                zIndex: "999",
                transition: "box-shadow .2s ease-in-out",
              }}
            >
              <ArrowBackOutlinedIcon sx={{ mr: 0, color: "primary" }} />
            </Fab>
            <h1
              style={{
                marginTop: "2%",
                fontSize: "15px",
                fontFamily: "Poppins",
                width: "100%",
              }}
            >
              <p
                style={{
                  marginTop: "0.5%",
                  marginLeft: "20.5%",
                  fontSize: "28px",
                  width: "65%",
                  marginBottom: "3%",
                  minWidth: "30%",
                  lineHeight: "1.3",
                  fontWeight: "100",
                  fontFamily: "Poppins",
                }}
              >
                Estimated Fare: £{sessionStorage.getItem("PriceOne")} - £
                {sessionStorage.getItem("priceThree")}
              </p>

              <div
                style={{
                  paddingBottom: "1%",
                  borderBottom: "2px solid white",
                  display: "flex",
                  marginTop: "6%",
                }}
              >
                <p
                  style={{
                    fontFamily: "Poppins",
                    marginRight: "36%",
                    marginLeft: "0%",
                    width: "39%",
                    fontWeight: "100",
                  }}
                >
                  <span>Date: </span>
                  {date}
                </p>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "100",
                    width: "21%",
                  }}
                >
                  <span>Time: </span>
                  {time}
                </p>
              </div>
            </h1>

            <div className="big">
              <p
                style={{
                  fontSize: "16px",
                  paddingLeft: "10px",
                  fontWeight: "100",
                  fontFamily: "Poppins",
                  paddingBottom: "10px",
                }}
              >
                <LocationOnIcon
                  sx={{
                    color: "white",
                    fontSize: "24px",
                    marginRight: "1%",
                    marginBottom: "-1.4%",
                  }}
                />
                Pick Up:
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "1%",
                  marginTop: "0%",
                  maxWidth: "99%",
                  fontFamily: "Poppins",
                  borderBottom: "2px solid #f2f2f2",
                  paddingBottom: "5px",
                  paddingLeft: "10px",
                  fontWeight: "100",
                }}
              >
                {origin}
              </p>
              <p
                style={{
                  marginTop: "4%",
                  fontSize: "16px",
                  paddingLeft: "10px",
                  fontWeight: "100",
                  fontFamily: "Poppins",
                  paddingBottom: "4px",
                }}
              >
                <WhereToVoteIcon
                  sx={{
                    color: "white",
                    fontSize: "24px",
                    marginRight: "1%",
                    marginBottom: "-1.4%",
                  }}
                />
                Drop Off:
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginTop: "1%",
                  marginBottom: "1%",
                  maxWidth: "99%",
                  fontFamily: "Poppins",
                  borderBottom: "2px solid #f2f2f2",
                  paddingBottom: "5px",
                  fontWeight: "100",

                  paddingLeft: "10px",
                }}
              >
                {destination}
              </p>
            </div>

            <div className="top--side">
              <i className="fas fa-plane"></i>
            </div>
          </div>
          <div className="bottom">
            <div className="column"></div>
            <div
              style={{
                marginTop: "-17%",
                marginLeft: "5%",
                marginBottom: "1%",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginTop: "10%",
                  width: "97%",
                }}
              >
                {/* Conditionally render name and mobile number fields based on userId */}
                {isLoggedInZ === "N" && (
                  <>
                    <div style={{ marginRight: "10%", width: "35%" }}>
                      <label
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          color: "black",
                        }}
                      >
                        Name:
                        <input
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "black",
                            height: "25px",
                          }}
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    <div style={{ width: "48%" }}>
                      <label
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          color: "black",
                        }}
                      >
                        Mobile Number:
                        <input
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            color: "black",
                            height: "25px",
                          }}
                          type="tel"
                          name="phone"
                          onChange={handleChange}
                          value={formData.phone}
                        />
                      </label>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Conditionally render email field based on userId */}
            {isLoggedInZ === "N" && (
              <div
                style={{
                  marginTop: "2%",
                  marginLeft: "5%",
                }}
              >
                <div style={{ marginRight: "5%" }}>
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      color: "black",
                    }}
                  >
                    Email:
                    <input
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "black",
                        height: "25px",
                        width: "95%",
                      }}
                      value={formData.email}
                      onChange={handleChange}
                      type="text"
                      name="email"
                    />
                  </label>
                </div>
              </div>
            )}
            <div style={{ display: "flex", marginBottom: "10%" }}>
              <Button
                onClick={noteModal.toggle}
                style={{
                  width: "45%",
                  backgroundColor: process.env.REACT_APP_COLOUR,
                  background: process.env.REACT_APP_COLOUR,
                  height: "99%",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  color: "white",
                  marginLeft: "2%",
                  marginTop: "7%",
                  marginRight: "4%",
                }}
                type="button"
              >
                + Add Note
              </Button>
              <Button
                onClick={handleSubmit}
                style={{
                  width: "50%",
                  height: "99%",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  color: "White",
                  background: process.env.REACT_APP_COLOUR,
                  backgroundColor: "#1B5879",
                  marginLeft: "2%",
                  marginTop: "7%",
                  marginRight: "4%",
                }}
                type="submit"
                className="form-button"
              >
                → Submit Request
              </Button>
            </div>
          </div>
        </div>
      </div>
      {noteModal.isOpen && (
        <NoteModal
          toggle={noteModal.toggle}
          onClose={handleCancelNote}
          // onSave={handleSaveNote}
          isOpen={noteModal.isOpen}
        />
      )}
      {paymentModal.isOpen && (
        // Render your payment modal component here
        <Modal isOpen={paymentModal.isOpen} toggle={paymentModal.toggle} />
      )}
    </>
  );
};
export default AnotherForm;
