import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ResponsiveAppBar from "./toolBar";
import FormContainer from "../formComponents/form";
import { useLocation } from "../../helpers/LocationContext";
import { useCurrentLocation } from "../../helpers/CurrentLocationContext";
import pinpoint from "./assets/pinpoint.png";
import { useOriginLocation } from "../../helpers/OriginLocationContext";
import * as maptilersdk from "@maptiler/sdk";
import "@maptiler/sdk/dist/maptiler-sdk.css";
import ContentFormContainer from "./contentContainer";

const MainContainer: React.FC<{ onSubmit: (data: any) => void }> = ({
  onSubmit,
}) => {
  // const [map, setMap] = useState<maptilersdk.Map | null>(null);
  // const [originMarker, setOriginMarker] = useState<maptilersdk.Marker | null>(
  //   null
  // );
  // const [destinationMarker, setDestinationMarker] =
  //   useState<maptilersdk.Marker | null>(null);
  // const { originLocation } = useOriginLocation();
  // const { location } = useLocation();
  // const { currentLocation, isFetching } = useCurrentLocation();

  // maptilersdk.config.apiKey = "E8Qi6aHnTsHLD84o03FZ";

  // useEffect(() => {
  //   if (!isFetching) {
  //     if (!map) {
  //       const initialCenter: maptilersdk.LngLatLike = currentLocation
  //         ? [currentLocation.lng, currentLocation.lat]
  //         : [1.2062, 52.7667]; // Default coordinates if current location is not available

  //       const newMap = new maptilersdk.Map({
  //         container: "map", // container's id or the HTML element in which SDK will render the map
  //         // style: 'https://api.maptiler.com/maps/voyager/style.json?key=E8Qi6aHnTsHLD84o03FZ',
  //         center: initialCenter,
  //         zoom: 12,
  //       });

  //       setMap(newMap);
  //       // Set a marker at the user's current location
  //       const newOriginMarker = new maptilersdk.Marker({ color: "#1B5879" })
  //         .setLngLat([
  //           originLocation?.lng ?? 0.1276,
  //           originLocation?.lat ?? 51.5072,
  //         ])
  //         .setPopup(new maptilersdk.Popup().setHTML("Pick Up"))
  //         .addTo(newMap);
  //       setOriginMarker(newOriginMarker);

  //       // Set a marker at the destination location
  //       const newDestinationMarker = new maptilersdk.Marker({
  //         color: "#1B5879",
  //       })
  //         .setLngLat([location?.lng ?? 0.1276, location?.lat ?? 51.5072])
  //         .addTo(newMap);
  //       setDestinationMarker(newDestinationMarker);
  //     }

  //     if (
  //       originLocation &&
  //       originLocation.lat !== undefined &&
  //       originLocation.lng !== undefined
  //     ) {
  //       // Update origin marker position based on new origin location
  //       originMarker?.setLngLat([originLocation.lng, originLocation.lat]);
  //       map?.flyTo({
  //         center: [originLocation.lng, originLocation.lat],
  //         zoom: 13,
  //       });
  //     }

  //     if (
  //       location &&
  //       location.lat !== undefined &&
  //       location.lng !== undefined
  //     ) {
  //       // Update destination marker position based on new location
  //       destinationMarker?.setLngLat([location.lng, location.lat]);

  //       // Fit the map bounds to show both markers and the polyline
  //       const bounds = new maptilersdk.LngLatBounds()
  //         .extend([
  //           originLocation?.lng ?? 0.1276,
  //           originLocation?.lat ?? 51.5072,
  //         ])
  //         .extend([location.lng, location.lat]);
  //       map?.fitBounds(bounds, { padding: 50 });
  //     }
  //   }
  // }, [
  //   location,
  //   map,
  //   originMarker,
  //   destinationMarker,
  //   currentLocation,
  //   isFetching,
  //   originLocation,
  // ]);

  const handleSubmit = (formData: any) => {
    console.log("Form submitted with data:", formData);
  };

  return (
    <>
      <div className="main-container">
        <ResponsiveAppBar />
        {/* <div id="map">
       
        </div> */}
        <div style={{ display: "flex", height: "120%", gap: "4%" }}>
          <FormContainer onSubmit={handleSubmit} />
          {/* <ContentFormContainer onSubmit={handleSubmit} /> */}
        </div>
        {/* <div className="footer">
          <p>&copy; 2024 Marandy. All rights reserved.</p>
          <p>
            Designed with <span style={{ color: "red" }}>❤</span>
          </p>
        </div> */}
      </div>
    </>
  );
};

export default MainContainer;
