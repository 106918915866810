import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import QrCodeIcon from "@mui/icons-material/QrCode";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import black from "../../assets/black.svg";
import googleplaybadge from "../../assets/google-play-badge.png";
import useModal from "../../helpers/useModal";
// import Modal from "../modalComponents/modal";
import { BrowserRouter as Router, Link } from "react-router-dom";
import QRCode from "qrcode.react";
import Modal from "@mui/material/Modal";
import OriginSearchBar from "../searchComponents/originSearchBar";

interface QRCodeProps {
  param1: string;
  param2: string;
}

const TemporaryDrawer: React.FC = () => {
  type Anchor = "left";
  const [state, setState] = React.useState({
    left: false,
    isModalOpen: false,
    param1: "",
    param2: "",
  });

  const paymentModal = useModal();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleModalOpen = () => {
    console.log("test");
  };

  const handleModalClose = () => {
    setState((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };
  const handleGenerateQRCode = () => {
    // You can perform validation here before generating the QR code
    generateQRCode({ param1: state.param1, param2: state.param2 });
    handleModalClose();
    console.log(state.param1);
  };
  const generateQRCode: React.FC<QRCodeProps> = ({ param1, param2 }) => {
    const baseUrl = "https://booking.safekab.co"; // Replace with your actual base URL
    const urlWithParams = `${baseUrl}/?param1=${param1}&param2=${param2}`;

    return (
      <QRCode
        value={urlWithParams}
        size={200} // Adjust the size as needed
        renderAs="svg" // Use SVG rendering for better compatibility
      />
    );
  };
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/* Use the toggle method from the useModal hook */}
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <ListItem key="Home" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
        </Link>
        {/* <ListItem key="Payment" disablePadding onClick={paymentModal.toggle}>
          <ListItemButton>
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary="Payment" />
          </ListItemButton>
        </ListItem> */}
        <Link to="/orders" style={{ textDecoration: "none", color: "inherit" }}>
          <ListItem key="History" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="History" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link
          to="/Support"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItem key="Support" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HelpCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItemButton>
          </ListItem>
        </Link>
        {/* <ListItem key="GenerateQRCode" disablePadding>
          <ListItemButton onClick={handleOpen}>
            <ListItemIcon>
              <QrCodeIcon />
            </ListItemIcon>
            <ListItemText primary="Generate QR Code" />
          </ListItemButton>
        </ListItem> */}
        {/* <ListItem key="GenerateQRCode" disablePadding>
          <ListItemButton>
            {generateQRCode({ param1: "value1", param2: "value2" })}
          </ListItemButton>
        </ListItem> */}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer("left", true)}
        edge="start"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={state.left}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
        <a
          href="https://apps.apple.com/gb/app/safekab/id1593605417"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={black}
            alt="Logo"
            style={{
              marginTop: "4%",
              marginLeft: "6%",
              alignItems: "center",
              minHeight: "70px",
              marginBottom: "10px",
            }}
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=co.safekab.customer&hl=en&gl=US&pli=1"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={googleplaybadge}
            alt="Logo"
            style={{ maxHeight: "90px", maxWidth: "250px" }}
          />
        </a>
      </Drawer>

      {/* {paymentModal.isOpen && (
        // Render your payment modal component here
        <Modal isOpen={paymentModal.isOpen} toggle={paymentModal.toggle} />
      )} */}

      <Modal open={open} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 2,
            zIndex: 99999,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <DialogTitle>Enter Parameters</DialogTitle>
          <DialogContent>
            <OriginSearchBar
              onSearch={function (query: string): void {
                throw new Error("Function not implemented.");
              }}
              onSelectedDescription={function (description: string): void {
                throw new Error("Function not implemented.");
              }}
            ></OriginSearchBar>
            <TextField
              label="Parameter 1"
              name="param1"
              value={state.param1}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Parameter 2"
              name="param2"
              value={state.param2}
              onChange={handleInputChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button onClick={handleGenerateQRCode}>Generate QR Code</Button>
          </DialogActions>
        </Box>
      </Modal>
    </div>
  );
};

export default TemporaryDrawer;
