import React, { useEffect, useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";

import PaymentForm from "../modalComponents/paymentForm";
import { loadStripe } from "@stripe/stripe-js";
import ResponsiveAppBar from "../mainPageComponents/toolBar";

const PaymentsPage: React.FC<{ onSubmit: (result: any) => void }> = ({}) => {
  const [options, setOptions] = useState<any>();

  const stripePromise = loadStripe(
    "pk_live_51LAupvLftwhjXz1dN1JDGSE44twj7YwppoLSfIRSy0dMYZRN3cdkdDoGeyyhI6ADDCIfdYBz87TNY3kQIxoqqiqm00LW1fDSXO"
  );

  console.log("e", process.env.REACT_APP_STRIPE_KEY);
  const PriceThree = sessionStorage.getItem("priceThree");
  const amount = parseFloat(PriceThree ?? "") * 100;
  console.log("testamount", amount);
  const stringAmount = amount.toString();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://safekab.co/onlinebooking/create.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              amount: amount,
            }),
          }
        );
        console.log("amount", stringAmount);

        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData);
          sessionStorage.setItem("clientSecret", responseData.clientSecret);
          const newOptions = {
            clientSecret: responseData.clientSecret,
            elements: {
              style: {
                base: {
                  fontSize: "16px",
                  color: "#32325d",
                  fontFamily: "Arial, sans-serif",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#fa755a",
                  iconColor: "#fa755a",
                },
              },
            },
          };

          setOptions(newOptions);
        } else {
          console.error("Error fetching data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const handlePaymentResult = async (result: any) => {
    // Handle the payment result here
    console.log("Payment result:", result);

    if (result.success) {
      console.log("Payment successful", result.success);
    }
  };

  // Custom style for CardElement
  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const appearance = {
    theme: "flat" as const,
  };
  const clientSecret = sessionStorage.getItem("clientSecret");
  const stripeOptions = {
    clientSecret: clientSecret || undefined,
    appearance,
  };

  return (
    <>
      <ResponsiveAppBar />

      <div>
        <div
          style={{
            backgroundColor: process.env.REACT_APP_COLOUR,
            borderTop: "1px solid white",
            borderBottom: "1px solid white",
          }}
        >
          <h1
            style={{
              fontFamily: "Poppins",
              paddingTop: "2%",
              color: "white",
              paddingBottom: "0%",
              textAlign: "center",
            }}
          >
            Payment of : £{sessionStorage.getItem("priceThree")}*
          </h1>
          <h5
            style={{
              fontFamily: "Poppins",
              paddingTop: "0%",
              color: "white",
              paddingBottom: "1%",
              textAlign: "center",
            }}
          >
            *: any changes in fare will be reflected automatically.
          </h5>
        </div>
        <div
          style={{
            backgroundColor: process.env.REACT_APP_COLOUR,
            borderTop: "1px solid white",
            borderBottom: "1px solid white",
          }}
        >
          <h1
            style={{
              fontFamily: "Poppins",
              paddingTop: "0.5%",
              color: process.env.REACT_APP_COLOUR,
              paddingBottom: "0%",
              textAlign: "center",
            }}
          >
            .
          </h1>
        </div>
        {clientSecret ? (
          <Elements stripe={stripePromise} options={stripeOptions}>
            <PaymentForm
              onSubmit={handlePaymentResult}
              cardElementOptions={cardElementOptions}
            />
          </Elements>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </>
  );
};

export default PaymentsPage;
