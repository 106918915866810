
// index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { LocationProvider } from './helpers/LocationContext';
import { CurrentLocationProvider } from './helpers/CurrentLocationContext';
import { OriginLocationProvider } from './helpers/OriginLocationContext';

ReactDOM.render(
  <React.StrictMode>
    <OriginLocationProvider>
   <CurrentLocationProvider>
    <LocationProvider> 
    <App onSubmit={function (data: any): void {
          throw new Error('Function not implemented.');
        } } /> 
    </LocationProvider>
   </CurrentLocationProvider>
   </OriginLocationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);