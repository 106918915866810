import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import "../../styles/main.css";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchBar from "../searchComponents/searchBar";
import OriginSearchBar from "../searchComponents/originSearchBar";
import CommuteIcon from "@mui/icons-material/Commute";
import AnotherForm from "./quoteForm";
import { useLocation } from "../../helpers/LocationContext";
import { useOriginLocation } from "../../helpers/OriginLocationContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Autocomplete,
  Box,
  Fab,
  IconButton,
  SvgIcon,
  TextField,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const options = [
  {
    label: "Car",
    value: "Car",
    image: "https://marandy.com/app_img/Car.png",
  },
  {
    label: "Estate",
    value: "Estate",
    image: "https://marandy.com/app_img/Est.png",
  },
  {
    label: "MPV",
    value: "MPV",
    image: "https://marandy.com/app_img/MPV.png",
  },
];

const FormContainer: React.FC<{ onSubmit: (data: any) => void }> = ({
  onSubmit,
}) => {
  const queryString = window.location.search;
  console.log(queryString);
  const urlParams = new URLSearchParams(queryString);
  const paramAddress = urlParams.get("address");
  console.log(paramAddress);
  const paramLat = urlParams.get("lat");
  console.log(paramLat);
  const paramLng = urlParams.get("lng");
  console.log(paramLng);
  const [inputLabelMarginTop, setInputLabelMarginTop] = useState<string>("2px");

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<Dayjs>(dayjs());
  const [selectedRide, setSelectedRide] = useState<{
    label: string;
    value: string;
    image: string;
  } | null>(null);
  const [selectedDate, handleDateChange] = useState<Dayjs | null>(dayjs());
  const { location } = useLocation();
  const { originLocation } = useOriginLocation();

  const time = value.format("HH:mm"); // Format time as 'HH:mm'
  const date = selectedDate?.format("DD / MM / YYYY"); // Format date as 'DD/MM/YYYY'

  const [showAnotherForm, setShowAnotherForm] = useState(false);
  const [selectedDescriptionOrigin, setSelectedDescriptionOrigin] = useState<
    string | null
  >(null);

  const [selectedDescriptionDestination, setSelectedDescriptionDestination] =
    useState<string | null>(null);

  const [formData, setFormData] = useState({
    from: [selectedDescriptionOrigin, originLocation?.lat, originLocation?.lng],
    to: [selectedDescriptionDestination, location?.lat, location?.lng],
    date: date || "",
    time: time || "",
    type: selectedRide?.label,
  });

  sessionStorage.setItem("Time", time);
  sessionStorage.setItem("Date", date ?? "");
  sessionStorage.setItem("Car", selectedRide?.label ?? "");

  const handleSelectedDestination = (description: string) => {
    setSelectedDescriptionDestination(description);
    setFormData((prevData) => ({
      ...prevData,
      to: [description, location?.lat, location?.lng],
    }));
    sessionStorage.setItem("Destination", description);
  };

  useEffect(() => {
    if (paramAddress !== null) {
      setSelectedDescriptionOrigin(paramAddress);
      sessionStorage.setItem("Origin", paramAddress);
    }
  }, [paramAddress]);

  console.log("te", selectedDescriptionOrigin);

  const handleSelectedOrigin = (description: string) => {
    // Check if paramAddress exists
    if (paramAddress !== null) {
      sessionStorage.setItem("Origin", paramAddress);
      setSelectedDescriptionOrigin(paramAddress);
    } else {
      setSelectedDescriptionOrigin(description);
    }
    const originDesc = description || paramAddress;
    setFormData((prevData) => ({
      ...prevData,
      from: [description, originLocation?.lat, originLocation?.lng],
    }));
    sessionStorage.setItem("Origin", description);
  };

  console.log("og", sessionStorage.getItem("Origin"));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !selectedDescriptionOrigin ||
      !selectedDescriptionDestination ||
      !selectedRide ||
      !selectedDate ||
      !value
    ) {
      alert("Please fill in the remaining fields!");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch("https://booking.api.safekab.co/getQuote", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userID: " -1",
          companyID: "SafeKab",
          versionID: "v5",
          userType: "User",
        },
        body: JSON.stringify({
          command: "GET_QUOTE",
          pLng: sessionStorage.getItem("pickupLng") || paramLng,
          pLat: sessionStorage.getItem("pickupLat") || paramLat,
          dLat: sessionStorage.getItem("dropLat"),
          dLng: sessionStorage.getItem("dropLng"),
          cartype: "Car",
          priority: "0",
          mileage: "8.74",
          CompanyID: "SafeKabInv",
          airportTransfer: "",
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Request submitted successfully", responseData);
        const priceOneNumber = responseData.priceOne;
        const roundedPriceOneNumber = (
          Math.round(priceOneNumber * 100) / 100
        ).toFixed(2);
        const priceOne = roundedPriceOneNumber.toString();
        sessionStorage.setItem("PriceOne", priceOne);
        const priceThreeNumber = responseData.priceThree;
        const roundedPriceThreeNumber = (
          Math.round(priceThreeNumber * 100) / 100
        ).toFixed(2);
        const priceThree = roundedPriceThreeNumber.toString();
        sessionStorage.setItem("priceThree", priceThree);
        setTimeout(() => {
          setSubmitted(true);
          setShowAnotherForm(true);
          setLoading(false);
        }, 2000);
      } else {
        console.error("Error submitting request");
        alert("There was an issue with the request. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      alert(
        "There was a problem processing your request. Please try again later."
      );
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const thirdResponse = await fetch(
  //         "https://booking.safekab.co/submitRequest",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             authorization: "BASIC TWFyYW5keTpDaXR5R3VpZGU=",
  //             userID: encodedUserID,
  //             usertype: "User",
  //             companyID: "SafeKab",
  //             versionID: "v10",
  //             PhoneNumber: tel ?? "",
  //             lat: "52.7802106",
  //             lng: "-1.2108441",
  //           },
  //           body: JSON.stringify({
  //             command: "JBA",
  //             amount: priceThree,
  //             PickupAddress: origin,
  //             PLat: pLat,
  //             PLng: pLng,
  //             DropoffAddress: destination,
  //             DLat: dLat,
  //             DLng: dLng,
  //             CarType: car,
  //             notes: "test",
  //             paymethod: "Cash",
  //             mileage: Mileage,
  //             priority: "0",
  //             jobType: "FTJOB",
  //           }),
  //         }
  //       );

  //       if (thirdResponse.ok) {
  //         const thirdResponseData = await thirdResponse.json();
  //         console.log(
  //           "Second request submitted successfully",
  //           thirdResponseData
  //         );

  //         navigate("/orders");
  //       } else {
  //         console.error("Error submitting third request");
  //         setLoadingText("Error processing your request");
  //         alert("Error processing your request");
  //         setTimeout(() => {
  //           window.location.href = "http://localhost:3000/";
  //         }, 2000);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //       setLoadingText("Error processing your request");
  //       alert("Error processing your request");
  //       setTimeout(() => {
  //         window.location.href = "http://localhost:3000/";
  //       }, 2000);
  //     }
  //   };
  //   fetchData();
  // }, []);
  const handleRefreshPage = () => {
    const baseUrl = window.location.origin + window.location.pathname;
    window.location.href = baseUrl; // Navigate to the base URL without any query parameters
  };
  return (
    <form
      noValidate
      className={`form-container ${submitted ? "fade-out" : ""}`}
      onSubmit={handleSubmit}
    >
      {submitted ? (
        <AnotherForm onClose={() => setSubmitted(false)} isLoggedIn={false} />
      ) : (
        <>
          <div
            style={{
              background: process.env.REACT_APP_COLOUR,
              borderRadius: "0.5rem",
              marginBottom: "12%",
              marginTop: "5%",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          >
            <p
              style={{
                fontFamily: "Futura",
                fontSize: "2rem",
                color: "white",
                textAlign: "center",
                padding: "1rem 0",
              }}
            >
              Get Instant Quote!
            </p>
          </div>
          {paramAddress ? (
            // Render the text field if paramAddress is present
            <Box
              sx={{
                display: "flex",
                height: "45px",
                marginBottom: "5%",
                marginTop: "0%",
                marginLeft: "2%",
                width: "95%",
                alignItems: "center",
              }}
            >
              <SvgIcon sx={{ marginRight: "2%", color: "#1B5879" }}>
                <LocationOnIcon />
              </SvgIcon>
              <TextField
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  borderRadius: "5px",
                  "& .MuiInputBase-input": {
                    fontFamily: "Poppins",
                    padding: "10px",
                  },
                }}
                value={paramAddress}
                label="Pick Up"
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton color="primary" aria-label="Search">
                        <SearchIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        aria-label="Search"
                        onClick={handleRefreshPage}
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    fontFamily: "Poppins",
                    marginTop: inputLabelMarginTop,
                  },
                }}
              />

              {/* <button onClick={handleRefreshPage}>X</button> */}
            </Box>
          ) : (
            // Render the OriginSearchBar if paramAddress is not present
            <OriginSearchBar
              onSearch={() => {}}
              onSelectedDescription={handleSelectedOrigin}
            />
          )}
          <MoreVertIcon
            sx={{
              color: "#1B5879",
              fontSize: "38px",
              marginLeft: "45%",
              marginTop: "-3%",
            }}
          />
          <SearchBar
            onSearch={() => {}}
            onSelectedDescription={handleSelectedDestination}
          />
          <Autocomplete
            value={selectedRide}
            onChange={(event, newValue) => {
              setSelectedRide(newValue);
            }}
            options={options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <Box
                sx={{
                  marginLeft: "2%",
                  width: "95%",
                  display: "flex",
                  fontFamily: "Poppins",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <SvgIcon style={{ color: process.env.REACT_APP_COLOUR }}>
                  <CommuteIcon />
                </SvgIcon>
                <TextField
                  {...params}
                  label="Vehichle Type"
                  variant="outlined"
                  size="small"
                  sx={{
                    fontFamily: "Poppins",
                    marginTop: "5%",
                    marginBottom: "3%",
                    width: "100%",
                    "& .MuiInputBase-root": {
                      borderRadius: "4px",
                      backgroundColor: "white",
                      fontFamily: "Poppins",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "blue",
                    },
                  }}
                  InputLabelProps={{ sx: { fontFamily: "Poppins" } }}
                />
              </Box>
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    paddingBottom: "10px",
                    borderBottom: "1px solid black",
                    width: "100%",
                  }}
                >
                  <img
                    src={option.image}
                    alt={option.label}
                    style={{ width: "43px", height: "34px" }}
                  />
                  {option.label}
                </Box>
              </li>
            )}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "6%",
              marginLeft: "7.5%",
              marginRight: "2%",
              gap: "5%",
              marginTop: "5%",
              width: "95%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={selectedDate}
                onChange={(date) => {
                  handleDateChange(date);
                }}
                sx={{
                  fontFamily: "Poppins",

                  width: "44%",
                  "& .MuiInputBase-root": {
                    borderRadius: "4px",
                    backgroundColor: "white",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "blue",
                  },
                }}
                label="Date"
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={value}
                onChange={(newValue) => setValue(newValue ?? dayjs())}
                sx={{
                  fontFamily: "Poppins",
                  width: "45%",
                  "& .MuiInputBase-root": {
                    borderRadius: "4px",
                    backgroundColor: "white",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "blue",
                  },
                }}
                label="Time"
              />
            </LocalizationProvider>
          </div>

          <div style={{ marginTop: "6%" }}></div>
          {loading ? (
            <CircularProgress
              style={{
                alignItems: "center",
                marginTop: "1%",
                marginLeft: "45%",
              }}
            />
          ) : (
            <Button
              type="submit"
              style={{
                width: "93%",
                fontFamily: "Poppins",
                fontSize: "18px",
                color: "White",
                background: process.env.REACT_APP_COLOUR,
                marginLeft: "3%",
              }}
              className="form-button"
            >
              → Get Quote
            </Button>
          )}
        </>
      )}
    </form>
  );
};

export default FormContainer;
