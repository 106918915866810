import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import TextField from "@mui/material/TextField";
import { Input, SvgIcon } from "@mui/material";
import { useLocation } from "../../helpers/LocationContext";

const SearchBar: React.FC<{
  onSearch: (query: string) => void;
  onSelectedDescription: (description: string) => void;
}> = ({ onSearch, onSelectedDescription }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedSuggestion, setSelectedSuggestion] = useState<string | null>(
    null
  );
  const { setLocation } = useLocation();
  const [inputLabelMarginTop, setInputLabelMarginTop] = useState<string>("7px");
  const [zIndexValue, setZIndexValue] = useState<string>("auto");

  const suggestionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node)
      ) {
        clearSuggestions();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleTextFieldClick = () => {
    if (!value && !selectedSuggestion) {
      setInputLabelMarginTop("0px");
      setZIndexValue("1");
    }
  };

  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "map",
    requestOptions: {
      region: "uk",
      language: "en_uk",
      // Define search scope here
    },
    debounce: 300,
  });

  const handleInput = async (e: { target: { value: string } }) => {
    const enteredValue = e.target.value;
    setValue(enteredValue);
    setSelectedSuggestion(null);

    const isRegexMatch = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]? ?[0-9][A-Z]{2}$/i.test(
      enteredValue
    );

    if (isRegexMatch) {
      try {
        const response = await fetch(
          "https://booking.api.safekab.co/postcode",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              command: "PC",
              PC: enteredValue,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const apiData = await response.json();
        console.log("apiData", apiData);

        // Update the autocomplete suggestions state with API results
        setAutocompleteSuggestions(apiData.PC || []);
      } catch (error) {
        console.error("Error making API call:", error);
      }
    } else {
      // Clear the autocomplete suggestions when the input is not a valid regex match
      setAutocompleteSuggestions([]);
    }
  };
  const handleSelect =
    ({
      description,
      Pickup,
      Lat,
      Lng,
    }: {
      description?: string;
      Pickup?: string;
      Lat?: string;
      Lng?: string;
    }) =>
    () => {
      const selectedDescription = description || Pickup || "";

      setValue(selectedDescription, false);
      setSelectedSuggestion(selectedDescription);
      onSelectedDescription(selectedDescription);

      let lat = 0;
      let lng = 0;

      if (Lat !== undefined && Lng !== undefined) {
        // For API results
        lat = parseInt(Lat, 10) / 3600000;
        lng = parseInt(Lng, 10) / 3600000;
        console.log("📍 Coordinates (API): ", { lat, lng });
        setLocation({ lat, lng });
        sessionStorage.setItem("dropLat", lat.toString());
        sessionStorage.setItem("dropLng", lng.toString());
      } else {
        // For autocomplete results
        getGeocode({ address: selectedDescription }).then((results) => {
          const { lat: autocompleteLat, lng: autocompleteLng } = getLatLng(
            results[0]
          );
          console.log("📍 Coordinates (Autocomplete): ", {
            lat: autocompleteLat,
            lng: autocompleteLng,
          });
          setLocation({ lat: autocompleteLat, lng: autocompleteLng });
          sessionStorage.setItem("dropLat", autocompleteLat.toString());
          sessionStorage.setItem("dropLng", autocompleteLng.toString());
        });
      }

      // Use setTimeout to delay the clearSuggestions call
      clearSuggestions();
      setAutocompleteSuggestions([]);
    };
  const renderSuggestions = (suggestions: any[]) => (
    <ul>
      {suggestions.map((suggestion) => {
        const place_id = suggestion.place_id || ""; // Adjust as needed

        let mainText: React.ReactNode = null;
        let secondaryText = "";

        if ("structured_formatting" in suggestion) {
          const { main_text, secondary_text, main_text_matched_substrings } =
            suggestion.structured_formatting;

          const matches = main_text_matched_substrings || [];
          const parts = parse(
            main_text,
            matches.map((match: { offset: number; length: number }) => [
              match.offset,
              match.offset + match.length,
            ])
          );

          mainText = (
            <span>
              {parts.map((part: any, index: number) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </span>
          );
          secondaryText = secondary_text;
        } else {
          mainText = suggestion.Pickup || "";
          secondaryText = "";
        }

        return (
          <li
            key={place_id}
            onClick={handleSelect(suggestion)}
            style={{
              padding: "10px",
              borderBottom: "1px solid #ccc",
              cursor: "pointer",
              backgroundColor: "white",
              fontFamily: "Poppins",
              fontSize: "24px",
              justifyContent: "space-between",
              alignItems: "left",
              transition: "background-color 0.3s, box-shadow 0.3s",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#f2f2f2";
              e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "white";
              e.currentTarget.style.boxShadow = "none";
            }}
          >
            <Grid container alignItems="center">
              <Grid item sx={{ width: "calc(100%)", wordWrap: "break-word" }}>
                <span
                  style={{
                    color: "black",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                  }}
                >
                  {mainText}
                </span>
                <Typography variant="body2" color="black">
                  {secondaryText}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      })}
    </ul>
  );

  const destination = sessionStorage.getItem("Destination");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "45px",
          marginBottom: "3%",
          marginTop: "0%",
          alignItems: "center",
          marginLeft: "2%",
          width: "95%",
        }}
      >
        <SvgIcon
          sx={{ marginRight: "2%", color: process.env.REACT_APP_COLOUR }}
        >
          <WhereToVoteIcon />
        </SvgIcon>
        <TextField
          size="small"
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleTextFieldClick}
          sx={{
            zIndex: "auto",
            borderRadius: "5px",
            "& .MuiInputBase-input": {
              fontFamily: "Poppins",
              padding: "10px",
            },
          }}
          value={selectedSuggestion || value}
          onChange={handleInput}
          label="Drop Off"
          InputProps={{
            endAdornment: (
              <IconButton color="primary" aria-label="Search">
                <SearchIcon />
              </IconButton>
            ),
          }}
          InputLabelProps={{
            sx: {
              zIndex: zIndexValue,
              fontFamily: "Poppins",
              marginTop: inputLabelMarginTop,
            },
          }}
        />
      </Box>
      <div ref={suggestionsRef}>
        <ol
          style={{
            position: "absolute",
            listStyle: "none",
            margin: 0,
            width: "90%",
            marginTop: "-010px",
            padding: 0,
            left: "47%",
            transform: "translateX(-50%)",
            zIndex: 999,
          }}
        >
          {status === "OK" && renderSuggestions(data)}
          {autocompleteSuggestions.length > 0 &&
            renderSuggestions(autocompleteSuggestions)}
        </ol>
      </div>
    </>
  );
};

export default SearchBar;
