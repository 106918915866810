import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useOriginLocation } from "../../helpers/OriginLocationContext";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { Input, SvgIcon, TextField } from "@mui/material";

const OriginSearchBar: React.FC<{
  onSearch: (query: string) => void;
  onSelectedDescription: (description: string) => void;
}> = ({ onSearch, onSelectedDescription }) => {
  const [selectedSuggestion, setSelectedSuggestion] = useState<string | null>(
    null
  );
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
  const { setOriginLocation } = useOriginLocation();
  const [inputLabelMarginTop, setInputLabelMarginTop] = useState<string>("7px");
  const suggestionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node)
      ) {
        clearSuggestions();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const searchBarElement = document.getElementById("origin-search-bar");
      const suggestionsListElement =
        document.getElementById("suggestions-list");
      if (
        searchBarElement &&
        !searchBarElement.contains(event.target as Node) &&
        suggestionsListElement &&
        !suggestionsListElement.contains(event.target as Node)
      ) {
        clearSuggestions();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleTextFieldClick = () => {
    if (!value && !selectedSuggestion) {
      setInputLabelMarginTop("0px");
    }
  };

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      region: "uk",
      language: "en_uk",
    },
    debounce: 300,
  });

  const handleInput = async (e: { target: { value: string } }) => {
    const enteredValue = e.target.value;
    setValue(enteredValue);
    setSelectedSuggestion(null);

    const isRegexMatch = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]? ?[0-9][A-Z]{2}$/i.test(
      enteredValue
    );

    if (isRegexMatch) {
      try {
        const response = await fetch(
          "https://booking.api.safekab.co/postcode",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              command: "PC",
              PC: enteredValue,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const apiData = await response.json();
        console.log("apiData", apiData);

        // Update the autocomplete suggestions state with API results
        setAutocompleteSuggestions(apiData.PC || []);
      } catch (error) {
        console.error("Error making API call:", error);
      }
    } else {
      // Clear the autocomplete suggestions when the input is not a valid regex match
      setAutocompleteSuggestions([]);
    }
  };
  const handleSelect =
    ({
      description,
      Pickup,
      Lat,
      Lng,
    }: {
      description?: string;
      Pickup?: string;
      Lat?: string;
      Lng?: string;
    }) =>
    () => {
      const selectedDescription = description || Pickup || "";

      setValue(selectedDescription, false);
      setSelectedSuggestion(selectedDescription);
      onSelectedDescription(selectedDescription);

      let lat = 0;
      let lng = 0;

      if (Lat !== undefined && Lng !== undefined) {
        // For API results
        lat = parseInt(Lat, 10) / 3600000;
        lng = parseInt(Lng, 10) / 3600000;
        console.log("📍 Coordinates (API): ", { lat, lng });
        setOriginLocation({ lat, lng });
        sessionStorage.setItem("pickupLat", lat.toString());
        sessionStorage.setItem("pickupLng", lng.toString());
      } else {
        // For autocomplete results
        getGeocode({ address: selectedDescription }).then((results) => {
          const { lat: autocompleteLat, lng: autocompleteLng } = getLatLng(
            results[0]
          );
          console.log("📍 Coordinates (Autocomplete): ", {
            lat: autocompleteLat,
            lng: autocompleteLng,
          });
          setOriginLocation({ lat: autocompleteLat, lng: autocompleteLng });
          sessionStorage.setItem("pickupLat", autocompleteLat.toString());
          sessionStorage.setItem("pickupLng", autocompleteLng.toString());
        });
      }

      // Use setTimeout to delay the clearSuggestions call
      clearSuggestions();
      setAutocompleteSuggestions([]);
    };
  const renderSuggestions = (suggestions: any[]) => (
    <div ref={suggestionsRef}>
      <ul>
        {suggestions.map((suggestion) => {
          const place_id = suggestion.place_id || ""; // Adjust as needed

          let mainText: React.ReactNode = null;
          let secondaryText = "";

          if ("structured_formatting" in suggestion) {
            const { main_text, secondary_text, main_text_matched_substrings } =
              suggestion.structured_formatting;

            const matches = main_text_matched_substrings || [];
            const parts = parse(
              main_text,
              matches.map((match: { offset: number; length: number }) => [
                match.offset,
                match.offset + match.length,
              ])
            );

            mainText = (
              <span>
                {parts.map((part: any, index: number) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
              </span>
            );
            secondaryText = secondary_text;
          } else {
            // Use data from the API response
            mainText = suggestion.Pickup || "";
            secondaryText = ""; // Add logic to extract secondary text from API response if needed
          }

          return (
            <li
              key={place_id}
              onClick={handleSelect(suggestion)}
              style={{
                padding: "10px",
                borderBottom: "1px solid #ccc",
                cursor: "pointer",
                backgroundColor: "white",
                fontFamily: "Poppins",
                justifyContent: "space-between",
                alignItems: "left",
                transition: "background-color 0.3s, box-shadow 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#f2f2f2";
                e.currentTarget.style.boxShadow =
                  "0 4px 8px rgba(0, 0, 0, 0.1)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "white";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <Grid container alignItems="center">
                <Grid item sx={{ width: "calc(100%)", wordWrap: "break-word" }}>
                  <span
                    style={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "17px",
                    }}
                  >
                    {mainText}
                  </span>
                  <Typography variant="body2" color="black">
                    {secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "45px",
          marginBottom: "5%",
          marginTop: "0%",
          marginLeft: "2%",
          width: "95%",
          alignItems: "center",
        }}
      >
        <SvgIcon
          sx={{ marginRight: "2%", color: process.env.REACT_APP_COLOUR }}
        >
          <LocationOnIcon />
        </SvgIcon>
        <TextField
          size="small"
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleTextFieldClick}
          sx={{
            borderRadius: "5px",
            "& .MuiInputBase-input": {
              fontFamily: "Poppins",
              padding: "10px",
            },
          }}
          value={selectedSuggestion || value}
          onChange={handleInput}
          label="Pick Up"
          InputProps={{
            endAdornment: (
              <IconButton color="primary" aria-label="Search">
                <SearchIcon />
              </IconButton>
            ),
          }}
          InputLabelProps={{
            sx: {
              fontFamily: "Poppins",
              marginTop: inputLabelMarginTop,
            },
          }}
        />
      </Box>
      <div ref={suggestionsRef}>
        <ol
          style={{
            position: "absolute",
            listStyle: "none",
            margin: 0,
            width: "90%",
            marginTop: "-24px",
            padding: 0,
            left: "47%",
            transform: "translateX(-50%)", // Center the list relative to its container
            zIndex: 1000,
          }}
        >
          {status === "OK" && renderSuggestions(data)}
          {autocompleteSuggestions.length > 0 &&
            renderSuggestions(autocompleteSuggestions)}
        </ol>
      </div>
    </>
  );
};

export default OriginSearchBar;
