import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DateTime } from "luxon";
import * as Base64 from "base-64";

interface LoginModalProps {
  open: boolean;
  onClose: () => void;
  onSuccessfulLogin: (userData: {
    email: string;
    tel: string;
    name: string;
    account: string;
    pin: string;
  }) => void;
}
const LoginModal: React.FC<LoginModalProps> = ({
  open,
  onClose,
  onSuccessfulLogin,
}) => {
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [showAccountPinFields, setShowAccountPinFields] = useState(false);
  const [pin, setPin] = useState("");
  const [account, setAccount] = useState("");

  function decodeUserId(userId: string): number {
    const split = userId.split(".");
    const decodedTime = Base64.decode(split[0]);
    const timeStamp = DateTime.fromFormat(decodedTime, "dd/MM/yyyy HH:mm:ss");
    const decodeId = parseInt(split[1], 8);

    const p1 = decodeId + 74;
    const p2 = p1 / 3;
    const p3 = p2 + timeStamp.minute;
    const p4 = Math.round(
      Math.floor(p3 / (timeStamp.second > 0 ? timeStamp.second : 1))
    );

    return p4;
  }

  function encodeUserId(userId: number): string {
    const datetime = DateTime.now();
    const p1 = userId * (datetime.second > 0 ? datetime.second : 1);
    const p2 = p1 - datetime.minute;
    const p3 = p2 * 3;
    const p4 = p3 - 74;

    const encodedUserId = p4.toString(8);

    return `${btoa(datetime.toFormat("yyyy-MM-dd HH:mm:ss"))}.${encodedUserId}`;
  }
  const handleLogin = async () => {
    if (showAccountPinFields) {
      try {
        const response = await fetch(
          "https://booking.api.safekab.co/checkUser",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              userID: " -1",
              companyID: "SafeKab",
              userType: "User",
              authorization: " BASIC TWFyYW5keTpDaXR5R3VpZGU=",
              usertype: "U",
              versionID: "v10",
            },
            body: JSON.stringify({
              command: "login",
              tel: tel,
              email: email,
              username: name,
            }),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          console.log("First request submitted successfully", responseData);

          const userID = responseData.userID;
          const decodedUserID = decodeUserId(userID);
          console.log("Decoded user", decodedUserID);
          const encodedUserID = encodeUserId(decodedUserID);
          sessionStorage.setItem("savedUserID", encodedUserID);
          const accountType = responseData.account;
          const accountTypeX = sessionStorage.setItem(
            "accountType",
            accountType
          );
          console.log("Account type", accountTypeX);
          if (showAccountPinFields) {
            sessionStorage.setItem("account", account);
            sessionStorage.setItem("pin", pin);
            sessionStorage.setItem("Name", name);
            sessionStorage.setItem("Email", email);
            sessionStorage.setItem("Phone", tel);
          } else {
            sessionStorage.setItem("Name", name);
            sessionStorage.setItem("Email", email);
            sessionStorage.setItem("Phone", tel);
          }

          onSuccessfulLogin({
            email: showAccountPinFields ? "" : email,
            tel: showAccountPinFields ? "" : tel,
            name: showAccountPinFields ? "" : name,
            account: showAccountPinFields ? account : "",
            pin: showAccountPinFields ? pin : "",
          });

          onClose(); // Close the modal
        } else {
          console.error("Registration failed");
        }
      } catch (error) {
        console.error("Error during registration:", error);
        // Handle other potential errors
      }
    } else {
      try {
        const response = await fetch(
          "https://booking.api.safekab.co/checkNumber",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              userID: " -1",
              companyID: "SafeKab",
              userType: "User",
              authorization: " BASIC TWFyYW5keTpDaXR5R3VpZGU=",
              usertype: "U",
              versionID: "v10",
            },
            body: JSON.stringify({
              ...(showAccountPinFields
                ? {
                    command: "register",
                    tel: tel,
                    email: email,
                    username: name,
                  }
                : { command: "login", tel: tel, email: email, username: name }),
            }),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          console.log("First request submitted successfully", responseData);

          const userID = responseData.userID;
          const decodedUserID = decodeUserId(userID);
          console.log("Decoded user", decodedUserID);
          const encodedUserID = encodeUserId(decodedUserID);
          sessionStorage.setItem("savedUserID", encodedUserID);
          const accountType = responseData.account;
          const accountTypeX = sessionStorage.setItem(
            "accountType",
            accountType
          );
          console.log("Account type", accountTypeX);
          if (showAccountPinFields) {
            sessionStorage.setItem("account", account);
            sessionStorage.setItem("pin", pin);
            sessionStorage.setItem("Name", name);
            sessionStorage.setItem("Email", email);
            sessionStorage.setItem("Phone", tel);
          } else {
            sessionStorage.setItem("Name", name);
            sessionStorage.setItem("Email", email);
            sessionStorage.setItem("Phone", tel);
          }

          onSuccessfulLogin({
            email: showAccountPinFields ? "" : email,
            tel: showAccountPinFields ? "" : tel,
            name: showAccountPinFields ? "" : name,
            account: showAccountPinFields ? account : "",
            pin: showAccountPinFields ? pin : "",
          });

          onClose(); // Close the modal
        } else {
          console.error("Registration failed");
        }
      } catch (error) {
        console.error("Error during registration:", error);
        // Handle other potential errors
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        {!showAccountPinFields && (
          <>
            <>
              <Typography
                sx={{ fontFamily: "Poppins", color: "#1B5879" }}
                variant="h6"
                component="div"
                gutterBottom
              >
                Login
              </Typography>
              <TextField
                label="Email"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={showAccountPinFields}
              />
              <TextField
                label="Phone Number"
                fullWidth
                margin="normal"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                disabled={showAccountPinFields}
              />
            </>
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={showAccountPinFields}
            />
          </>
        )}
        {/* <div style={{ display: "flex" }}>
          <label
            style={{ color: "black", marginRight: "3.5%", marginTop: "5%" }}
          >
            Register
          </label>
          <input
            style={{ marginTop: "6.35%" }}
            type="checkbox"
            checked={showAccountPinFields}
            onChange={() => setShowAccountPinFields(!showAccountPinFields)}
          />
        </div> */}
        {/* Conditionally render account and pin fields based on checkbox state */}
        {showAccountPinFields && (
          <>
            {/* <Typography
              sx={{ fontFamily: "Poppins", color: "#1B5879" }}
              variant="h6"
              component="div"
              gutterBottom
            >
              Register
            </Typography> */}
            {/* <TextField
              label="Account"
              fullWidth
              margin="normal"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            />
            <TextField
              label="Pin"
              fullWidth
              margin="normal"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            /> */}
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // disabled={showAccountPinFields}
            />
            <TextField
              label="Phone Number"
              fullWidth
              margin="normal"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              // disabled={showAccountPinFields}
            />
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
              // disabled={showAccountPinFields}
            />
          </>
        )}
        <Button
          onClick={onClose}
          sx={{
            marginRight: "40%",
            mt: 3,
            width: "30%",
            backgroundColor: "#1B5879",
            color: "white",
            fontFamily: "Poppins",
          }}
        >
          Close
        </Button>
        <Button
          onClick={handleLogin}
          sx={{
            mt: 3,
            width: "30%",
            backgroundColor: "#1B5879",
            color: "white",
            fontFamily: "Poppins",
          }}
        >
          Login
        </Button>
      </Box>
    </Modal>
  );
};

export default LoginModal;
