// LoadingPage.tsx

import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { DateTime } from "luxon";
import * as Base64 from "base-64";
import ResponsiveAppBar from "../mainPageComponents/toolBar";
import { useNavigate } from "react-router-dom";

const LoadingPage: React.FC = () => {
  const [loadingText, setLoadingText] = useState(
    "Please wait, processing your request..."
  );
  const navigate = useNavigate();
  const pLng = sessionStorage.getItem("pickupLng");
  const pLat = sessionStorage.getItem("pickupLat");
  const dLat = sessionStorage.getItem("dropLat");
  const dLng = sessionStorage.getItem("dropLng");

  function decodeUserId(userId: string): number {
    const split = userId.split(".");
    const decodedTime = Base64.decode(split[0]);
    const timeStamp = DateTime.fromFormat(decodedTime, "dd/MM/yyyy HH:mm:ss");
    const decodeId = parseInt(split[1], 8);

    const p1 = decodeId + 74;
    const p2 = p1 / 3;
    const p3 = p2 + timeStamp.minute;
    const p4 = Math.round(
      Math.floor(p3 / (timeStamp.second > 0 ? timeStamp.second : 1))
    );

    return p4;
  }

  function encodeUserId(userId: number): string {
    const datetime = DateTime.now();
    const p1 = userId * (datetime.second > 0 ? datetime.second : 1);
    const p2 = p1 - datetime.minute;
    const p3 = p2 * 3;
    const p4 = p3 - 74;

    const encodedUserId = p4.toString(8);

    return `${btoa(datetime.toFormat("yyyy-MM-dd HH:mm:ss"))}.${encodedUserId}`;
  }

  const tel = sessionStorage.getItem("Phone");
  const email = sessionStorage.getItem("Email");
  const name = sessionStorage.getItem("Name");
  const car = sessionStorage.getItem("Car");

  const destination = sessionStorage.getItem("Destination");
  const origin = sessionStorage.getItem("Origin");
  const time = sessionStorage.getItem("Time");
  const date = sessionStorage.getItem("Date");
  const amount = parseInt(sessionStorage.getItem("priceThree") ?? "") * 100;
  useEffect(() => {
    const fetchData = async () => {
      try {
        // First API call
        const response = await fetch(
          "https://booking.api.safekab.co/checknumber",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              userID: " -1",
              companyID: "SafeKab",
              userType: "User",
              authorization: " BASIC TWFyYW5keTpDaXR5R3VpZGU=",
              usertype: "U",
              versionID: "v10",
            },
            body: JSON.stringify({
              command: "register",
              tel: tel,
              email: email,
              username: name,
            }),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          console.log("First request submitted successfully", responseData);
          const Mileage = responseData.mileage;
          const userID = responseData.userID;
          const decodedUserID = decodeUserId(userID);
          console.log("Decoded user", decodedUserID);
          const encodedUserID = encodeUserId(decodedUserID);
          sessionStorage.setItem("savedUserID", encodedUserID);
          const priceThree = sessionStorage.getItem("priceThree");

          const secondResponseX = await fetch(
            "https://booking.api.safekab.co/checkNumber",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                command: "LOGIN_TEL_NUMBER",
                tel: tel,
              }),
            }
          );
          if (secondResponseX.ok) {
            const secondResponseXdata = await secondResponseX.json();

            const thirdResponse = await fetch(
              "https://booking.api.safekab.co/submitRequest",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  authorization: "BASIC TWFyYW5keTpDaXR5R3VpZGU=",
                  userID: encodedUserID,
                  usertype: "User",
                  companyID: "SafeKab",
                  versionID: "v10",
                  PhoneNumber: tel ?? "",
                  lat: "52.7802106",
                  lng: "-1.2108441",
                },
                body: JSON.stringify({
                  command: "JBA",
                  amount: priceThree,
                  PickupAddress: origin,
                  PLat: pLat,
                  PLng: pLng,
                  DropoffAddress: destination,
                  DLat: dLat,
                  DLng: dLng,
                  CarType: car,
                  notes: "test",
                  paymethod: "Cash",
                  mileage: Mileage,
                  priority: "0",
                  jobType: "FTJOB",
                }),
              }
            );

            if (thirdResponse.ok) {
              const thirdResponseData = await thirdResponse.json();
              console.log(
                "Second request submitted successfully",
                thirdResponseData
              );
              const jobId = thirdResponseData.jobId;
              const job = sessionStorage.setItem("job", jobId);
              console.log(job);
              navigate("/success");
            } else {
              console.error("Error submitting third request");
              setLoadingText("Error processing your request");
              alert("Error processing your request");
              setTimeout(() => {
                window.location.href = "https://booking.safekab.co/";
              }, 2000);
            }
          } else {
            console.error("Error submitting second request");
            setLoadingText("Error processing your request");
            alert("Error processing your request");
            setTimeout(() => {
              window.location.href = "https://booking.safekab.co/";
            }, 2000);
          }
        } else {
          console.error("Error submitting first request");
          setLoadingText("Error processing your request");
          alert("Error processing your request");
          setTimeout(() => {
            window.location.href = "https://booking.safekab.co/";
          }, 2000);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoadingText("Error processing your request");
        alert("Error processing your request");
        setTimeout(() => {
          window.location.href = "https://booking.safekab.co/";
        }, 2000);
      }

      //     if (response.ok) {
      //       // Successful response, navigate to another page
      //       window.location.href = "/success-page"; // Replace with your actual success page
      //     } else {
      //       // Handle error response
      //       setLoadingText("Error processing your request");
      //       alert("Error processing your request");
      //       setTimeout(() => {
      //         // Redirect to the main page after showing the alert
      //         window.location.href = "/main-page"; // Replace with your actual main page
      //       }, 2000);
      //     }
      //   } catch (error) {
      //     console.error("Error:", error);
      //     setLoadingText("Error processing your request");
      //     alert("Error processing your request");
      //     setTimeout(() => {
      //       // Redirect to the main page after showing the alert
      //       window.location.href = "/main-page"; // Replace with your actual main page
      //     }, 2000);
      //   }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (
    <>
      <ResponsiveAppBar />
      <div style={{ textAlign: "center" }}>
        <p style={{ color: "black" }}>{loadingText}</p>
        <CircularProgress />{" "}
        {/* Use your preferred circular loading spinner component */}
      </div>
    </>
  );
};

export default LoadingPage;
