import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface CurrentLocationContextProps {
  children: ReactNode;
}

interface CurrentLocation {
  lat: number;
  lng: number;
}

interface CurrentLocationContextType {
  currentLocation: CurrentLocation | null;
  isFetching: boolean;
  setCurrentLocation: React.Dispatch<React.SetStateAction<CurrentLocation | null>>;
}

const CurrentLocationContext = createContext<CurrentLocationContextType | undefined>(undefined);

export const CurrentLocationProvider: React.FC<CurrentLocationContextProps> = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState<CurrentLocation | null>(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const getLocation = async () => {
      try {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
  
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
      } catch (error) {
        console.error('Error getting current location:', error);
        setCurrentLocation(null);
      } finally {
        setIsFetching(false); // Set isFetching to false regardless of success or failure
      }
    };
  
    getLocation();
  }, []);
  return (
    <CurrentLocationContext.Provider value={{ currentLocation, isFetching, setCurrentLocation }}>
      {children}
    </CurrentLocationContext.Provider>
  );
};

export const useCurrentLocation = () => {
  const context = useContext(CurrentLocationContext);
  if (!context) {
    throw new Error('useCurrentLocation must be used within a CurrentLocationProvider');
  }
  return context;
};