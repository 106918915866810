import React, { ChangeEvent, ReactNode, useState } from "react";
import ReactDOM from "react-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";

interface ModalProps {
  onClose: () => void;
  // onSave: (note: string) => void;
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
}

export default function NoteModal(props: ModalProps) {
  const [noteInput, setNoteInput] = useState("");

  const handleNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNoteInput(event.target.value);
  };

  const handleSaveClick = () => {
    sessionStorage.setItem("note", noteInput);
    // props.onSave(noteInput);
    props.toggle();
  };

  const note = sessionStorage.getItem("note");

  return (
    <>
      {props.isOpen && (
        <div className="modal-overlay" onClick={props.toggle}>
          <div
            onClick={(e) => e.stopPropagation()}
            className="modal-box"
            style={{ height: "40%" }}
          >
            <div className="container" style={{ zIndex: 1000 }}>
              <div style={{ backgroundColor: "#1B5879" }}>
                <h1
                  style={{
                    fontFamily: "Poppins",
                    paddingTop: "5%",
                    color: "white",
                    paddingBottom: "4%",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  Leave a Note
                </h1>
              </div>
              <Box sx={{ paddingTop: "2%", width: "97%", marginLeft: "2%" }}>
                <TextField
                  label="Write your note here"
                  variant="outlined"
                  value={noteInput || note}
                  fullWidth
                  multiline
                  rows={6}
                  onChange={handleNoteChange}
                />
              </Box>
              <div
                style={{ marginTop: "4%", marginLeft: "12%" }}
                className="modal-buttons"
              >
                <Button
                  style={{
                    paddingLeft: "1%",
                    paddingRight: "1%",
                    marginRight: "6%",
                    width: "40%",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    color: "White",
                    backgroundColor: "#1B5879",
                  }}
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
                <Button
                  style={{
                    paddingLeft: "1%",
                    width: "40%",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    color: "White",
                    backgroundColor: "#1B5879",
                  }}
                  onClick={props.toggle}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
