import React, { createContext, useContext, useState, ReactNode } from 'react';

interface OriginLocationContextProps {
  children: ReactNode;
}

interface Location {
  lat: number;
  lng: number;
}

interface OriginLocationContextType {
    originLocation: Location | null;
    setOriginLocation: React.Dispatch<React.SetStateAction<Location | null>>;
}

const OriginLocationContext = createContext<OriginLocationContextType | undefined>(undefined);

export const OriginLocationProvider: React.FC<OriginLocationContextProps> = ({ children }) => {
  const [originLocation, setOriginLocation] = useState<Location | null>(null);

  return (
    <OriginLocationContext.Provider value={{ originLocation, setOriginLocation }}>
      {children}
    </OriginLocationContext.Provider>
  );
};

export const useOriginLocation = () => {
  const context = useContext(OriginLocationContext);
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
};