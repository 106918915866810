import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import TemporaryDrawer from "./drawer";
import LoginModal from "./loginModal";
import AnotherForm from "../formComponents/quoteForm";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState<{
    email: string;
    tel: string;
    name: string;
  } | null>(null);
  useEffect(() => {
    // Check if isLoggedIn is true and store Y in session storage, else store N
    sessionStorage.setItem("isLoggedIn", isLoggedIn ? "Y" : "N");
  }, [isLoggedIn]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSuccessfulLogin = (userData: {
    email: string;
    tel: string;
    name: string;
  }) => {
    sessionStorage.setItem("userData", JSON.stringify(userData));
    setIsLoggedIn(true);
    setUserData(userData);
    window.location.reload();
  };

  const handleLogOut = () => {
    setIsLoggedIn(false);
    sessionStorage.removeItem("savedUserID");
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("Name");
    sessionStorage.removeItem("Phone");
    sessionStorage.removeItem("Email");
    sessionStorage.removeItem("accountType");
    window.location.reload();
  };

  useEffect(() => {
    const storedUserData = sessionStorage.getItem("userData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setIsLoggedIn(true);
      setUserData(parsedUserData);
    }
  }, []);

  return (
    <React.Fragment>
      <AppBar
        style={{ background: process.env.REACT_APP_COLOUR }}
        position="static"
      >
        <Container maxWidth="xl">
          <Toolbar>
            <TemporaryDrawer />
            <LocalTaxiIcon
              sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/ "
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "Oswald",
                fontWeight: 1400,
                letterSpacing: ".0rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              SafeKab
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ mr: 2 }}>
                {isLoggedIn ? (
                  <React.Fragment>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: "Poppins", color: "white" }}
                    >
                      Welcome, {userData?.name}!
                    </Typography>
                  </React.Fragment>
                ) : null}
              </Box>
              <Box>
                {isLoggedIn ? (
                  <Button
                    sx={{ color: "white", fontFamily: "Poppins" }}
                    onClick={handleLogOut}
                  >
                    Logout
                  </Button>
                ) : (
                  <Button onClick={handleOpenModal} sx={{ color: "white" }}>
                    Login
                  </Button>
                )}
              </Box>
            </Box>

            <LocalTaxiIcon
              sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
            />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".0em",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              SafeKab
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>

      {submitted && (
        <AnotherForm
          onClose={() => setSubmitted(false)}
          isLoggedIn={isLoggedIn}
        />
      )}

      <LoginModal
        onSuccessfulLogin={handleSuccessfulLogin}
        open={isModalOpen}
        onClose={handleCloseModal}
      />
    </React.Fragment>
  );
}

export default ResponsiveAppBar;
