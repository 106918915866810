import React, { useState, useEffect } from "react";
import ResponsiveAppBar from "../mainPageComponents/toolBar";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const OrdersPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [orders, setOrders] = useState<any[]>([]);
  const userId = sessionStorage.getItem("savedUserID");
  const date = sessionStorage.getItem("Date");
  const [selectedFilter, setSelectedFilter] = useState<string>("historycard");

  const [selectedDate, handleDateChange] = useState<Dayjs | null>(dayjs());
  const fromDatex = selectedDate?.format("DD / MM / YYYY"); // Format date as 'DD/MM/YYYY'
  const isLoggedIn = sessionStorage.getItem("isLoggedIn");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://booking.api.safekab.co/checkJobs",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              userID: userId ?? "",
              companyID: "SafeKab",
              userType: "User",
              authorization: "BASIC TWFyYW5keTpDaXR5R3VpZGU=",
              usertype: "U",
              versionID: "v10",
            },
            body: JSON.stringify({
              command: "CLIENT_HISTORY",
              limit: "20",
              page: "1",
              date: date || fromDate,
              type: selectedFilter,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setOrders(data);
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Error processing your request");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (fromDate) {
      setSearchTerm(`fromDate:${fromDate.toISOString().split("T")[0]}`);
    } else {
      setSearchTerm("");
    }
  }, [fromDate, toDate]);

  const handleSearch = async () => {
    if (!isLoggedIn || !userId) {
      // Show a pop-up or handle it in a way suitable for your application
      alert("Please login or make a booking.");
      return;
    }
    try {
      const response = await fetch("https://booking.api.safekab.co/checkJobs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userID: userId ?? "",
          companyID: "SafeKab",
          userType: "User",
          authorization: "BASIC TWFyYW5keTpDaXR5R3VpZGU=",
          usertype: "U",
          versionID: "v10",
        },
        body: JSON.stringify({
          command: "CLIENT_HISTORY",
          limit: "20",
          page: "1",
          date: fromDatex,

          type: selectedFilter,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setOrders(data);
      } else {
        // Handle error scenarios
        console.error("Error:", response.statusText);
        alert("Error processing your request");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error processing your request");
    }
  };
  if (!isLoggedIn || !userId) {
    return (
      <>
        <ResponsiveAppBar />
        <div
          style={{
            textAlign: "center",
            marginTop: "20%",
            backgroundColor: "black",
            fontSize: "40px",
          }}
        >
          <p>Please login or make a booking.</p>
        </div>
      </>
    );
  }

  return (
    <>
      <ResponsiveAppBar />
      <div>
        <h1
          style={{
            color: "black",
            textAlign: "center",
            paddingTop: "1%",
            borderBottom: "1px solid black",
          }}
        >
          Jobs History
        </h1>
        <div
          style={{
            paddingTop: "2%",
            marginBottom: "1%",
            display: "flex",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate}
              onChange={(date) => {
                handleDateChange(date);
              }}
              sx={{
                fontFamily: "Poppins",
                marginLeft: "1%",
                width: "24%",
                "& .MuiInputBase-root": {
                  borderRadius: "4px",
                  backgroundColor: "white",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "blue",
                },
              }}
              label="Date"
            />
          </LocalizationProvider>

          <div
            style={{
              marginLeft: "3%",
              marginRight: "1%",
              display: "flex",
              marginTop: "-1.4%",
              width: "45%",
            }}
          >
            <label
              style={{
                color: "#1B5879",
                fontFamily: "Poppins",
                marginTop: "4.5%",
                fontSize: "22px",
                marginRight: "4%",
                fontWeight: "bold",
              }}
            >
              Search Type:
            </label>
            <label
              style={{
                color: "#1B5879",
                fontFamily: "Poppins",
                marginTop: "4.9%",
                fontSize: "19px",
                marginRight: "2%",
              }}
            >
              Current
            </label>
            <input
              style={{ marginTop: "3.4%" }}
              type="radio"
              value="current"
              checked={selectedFilter === "current"}
              onChange={() => setSelectedFilter("current")}
            />
            <label
              style={{
                marginLeft: "3%",
                color: "#1B5879",
                fontFamily: "Poppins",
                marginTop: "4.9%",
                fontSize: "19px",
                marginRight: "2%",
              }}
            >
              History
            </label>
            <input
              style={{ marginTop: "3.4%" }}
              type="radio"
              value="historycard"
              checked={selectedFilter === "historycard"}
              onChange={() => setSelectedFilter("historycard")}
            />

            {/* <TextField
            style={{ marginRight: "2%", width: "20%" }}
            label="To Date"
            type="date"
            variant="outlined"
            value={toDate ? toDate.toISOString().split("T")[0] : ""}
            onChange={(e) => setToDate(new Date(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
            <Button
              style={{
                width: "25%",
                backgroundColor: "#1B5879",
                background: process.env.REACT_APP_COLOUR,
                height: "50%",
                fontFamily: "Poppins",
                fontSize: "14px",
                color: "white",
                marginLeft: "5%",
                marginTop: "4.5%",
                marginRight: "4%",
              }}
              type="button"
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        </div>

        {/* Orders Table */}
        {orders.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ borderTop: "1px solid black" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "#1B5879",
                      fontFamily: "Poppins",
                      fontWeight: "900",
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      color: "white",
                    }}
                  >
                    From Location
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#1B5879",
                      fontFamily: "Poppins",
                      fontWeight: "900",
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      color: "white",
                    }}
                  >
                    To Location
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#1B5879",
                      fontFamily: "Poppins",
                      fontWeight: "900",
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      color: "white",
                    }}
                  >
                    Cost
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#1B5879",
                      fontFamily: "Poppins",
                      fontWeight: "900",
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      color: "white",
                    }}
                  >
                    Date
                  </TableCell>

                  <TableCell
                    style={{
                      backgroundColor: "#1B5879",
                      fontFamily: "Poppins",
                      fontWeight: "900",
                      borderBottom: "1px solid black",
                      color: "white",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell style={{ borderRight: "1px Solid black" }}>
                      {order.pickupAddress}
                    </TableCell>
                    <TableCell style={{ borderRight: "1px Solid black" }}>
                      {order.dropOffAddress}
                    </TableCell>
                    <TableCell style={{ borderRight: "1px Solid black" }}>
                      £{order.Fare}
                    </TableCell>
                    <TableCell style={{ borderRight: "1px Solid black" }}>
                      {order.BookingDate}
                    </TableCell>

                    <TableCell>{order.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              borderTop: "1px solid black",
              color: "black",
              textAlign: "center",
              marginTop: "2%",
              fontSize: "40px",
            }}
          >
            <p
              style={{
                color: "black",
                textAlign: "center",
                marginTop: "15%",
                fontFamily: "Poppins",
                fontSize: "50px",
              }}
            >
              No Jobs found
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default OrdersPage;
