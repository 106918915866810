import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import { DateTime } from "luxon";
import * as Base64 from "base-64";

const PaymentForm: React.FC<{
  onSubmit: (result: any) => void;
  cardElementOptions: any;
}> = ({ onSubmit, cardElementOptions }) => {
  const stripe = useStripe();
  let elements = useElements();
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = sessionStorage.getItem("clientSecret");

    // new URLSearchParams(window.location.search).get(
    //   "payment_intent_client_secret"
    // );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const tel = sessionStorage.getItem("Phone");
  const email = sessionStorage.getItem("Email");
  const name = sessionStorage.getItem("Name");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://booking.safekab.co/processingRequest",
        payment_method_data: {
          billing_details: {
            name: name ?? "",
            email: email ?? "",
            phone: tel ?? "",
          },
        },
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message ?? "");
    } else {
      setMessage("An unexpected error occurred.");
    }

    if (error) {
      setMessage(error.message ?? "");
      setIsLoading(false);
    } else {
      setMessage("Payment succeeded!");
      try {
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const paymentElementOptions = {
    layout: "tabs" as const,
  };

  return (
    <form
      style={{ marginLeft: "2%", marginRight: "2%", marginTop: "2%" }}
      id="payment-form"
      onSubmit={handleSubmit}
    >
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Button
        style={{
          width: "93%",
          fontFamily: "Poppins",
          fontSize: "18px",
          color: "White",
          background: process.env.REACT_APP_COLOUR,
          marginLeft: "3%",
          marginTop: "5%",
        }}
        className="form-button"
        disabled={isLoading || !stripe || !elements}
        id="submit"
        type="submit"
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </Button>

      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default PaymentForm;
